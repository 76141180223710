import React, { useContext, useEffect, useState } from "react";

import AdvisorContainer from "../../layout/AdvisorContainer";
import { AppContext } from "../../AppRouter";
import { Box, Button, useTheme, Grid2 as Grid } from "@mui/material";
import { ReactGrid } from "@silevis/reactgrid";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { convertToISODate, getAllMonthsBetweenDates } from "../../util/time";
import { formatYearMonth } from "../../util/formatter";
import { getBlankRow, transformHammersToRows } from "./util";
import { DropdownCellTemplate } from "./cells/DropdownCell";
import { DimensionCellTemplate } from "./cells/DimensionCell";
import CircularLoading from "../../components/loading/CircularLoading";
import { HammerMeasure } from "../../client";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import { AddButtonCellTemplate } from "./cells/AddButtonCell";
import { DeleteButtonCellTemplate } from "./cells/DeleteButtonCell";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

const Hammer = () => {
    const { client, config, notify } = useContext(AppContext);
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const historicalMin = config.time.historical.min;
    const historicalMax = config.time.historical.max;
    const referenceMin = config.time.reference.min;
    const referenceMax = config.time.reference.max;

    const [hammers, setHammers] = useState(null);
    const [hammersMap, setHammersMap] = useState(null); // hammer.id -> hammer array index
    const [rows, setRows] = useState(null);
    const [changed, setChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isCommitting, setIsCommitting] = useState(false);
    const [startDate, setStartDate] = useState(referenceMin);
    const [endDate, setEndDate] = useState(referenceMax);

    function setHammersAndMap(hammersArray) {
        let mappedHammers = {};
        hammersArray.forEach((hammer, idx) => mappedHammers[hammer.id] = idx);
        setHammers(hammersArray);
        setHammersMap(mappedHammers);
    }

    /*
    * Grid Columns
    */

    const defaultColumns = ["enabled", "name", "document_type", "accounting_treatment", "measure"];
    const dimensionColumns = config.hammer.dimensions;
    const dateColumns = getAllMonthsBetweenDates(startDate, endDate).map(date => (convertToISODate(date)));

    const headerColumns = [
        ...defaultColumns.map(col => (col === "accounting_treatment" || col === "measure" || col === "name"
            ? { columnId: col, width: 180 } : { columnId: col, width: 100 })),
        ...dimensionColumns.map(col => ({ columnId: col, width: 180 })),
        ...dateColumns.map(col => ({ columnId: col, width: 90 })),
        { columnId: "delete", width: 60 },
    ];

    /*
    * Rows Hooks
    */

    useEffect(() => {
        if (hammers) {
            const newRows = transformHammersToRows(hammers, dimensionColumns, dateColumns, deleteHammer, config);
            if (newRows) {
                setRows(newRows);
            }
        }
    }, [hammers, startDate, endDate]);

    useEffect(() => {
        client.hammer.hammerListHammers()
            .then((clientHammers) => {
                if (location?.state?.hammer) {
                    const reportingHammer = createHammer(
                        location.state.hammer.name,
                        location.state.hammer.document_type,
                        location.state.hammer.accounting_treatment,
                        location.state.hammer.measure,
                        location.state.hammer.dimensions,
                        location.state.hammer.metadata,
                    );
                    navigate(location.pathname, { replace: true }); // Clears location state
                    setHammersAndMap([...clientHammers, reportingHammer]);
                    setChanged(true);
                } else {
                    setHammersAndMap(clientHammers);
                }
            })
            .catch((error) => {
                setHammersAndMap([]);
                notify.error(error, "hammer.fetch");
            });
    }, []);

    /*
    * Client Functions
    */

    const persistHammers = () => {
        setIsSaving(true);

        client.hammer.hammerPersistHammers(hammers)
            .then((persistedHammers) => {
                setHammersAndMap(persistedHammers);
                setChanged(false);
                setIsSaving(false);
            })
            .catch((error) => {
                notify.error(error, "hammer.persist");
                setIsSaving(false);
            });
    };

    const commitHammers = () => {
        setIsCommitting(true);

        client.hammer.hammerCommitHammers()
            .then(() => {
                setIsCommitting(false);
            })
            .catch((error) => {
                notify.error(error, "hammer.commit");
                setIsCommitting(false);
            });
    };

    /*
    * Grid Functions
    */

    const getHeaderCell = (text, alignment) => {
        return {
            type: "header",
            text: text,
            style: {
                background: theme.palette.grey["700"],
                color: "white",
                justifyContent: alignment,
            },
        };
    };

    const getHeaderRow = () => {
        return {
            rowId: "header",
            cells: [
                ...defaultColumns.map(col => getHeaderCell(config.i18n.hammer[col], col === "enabled" ? "center" : "left")),
                ...dimensionColumns.map(dimension => getHeaderCell(config.i18n.Dimension[dimension], "left")),
                ...dateColumns.map(date => getHeaderCell(formatYearMonth(date, config.locale), "right")),
                getHeaderCell(config.i18n.button.delete, "center"),
            ],
        };
    };

    const getCellValue = ({ newCell }) => {
        switch (newCell.type) {
            case "mui_dropdown":
            case "number":
            case "dimension":
                return newCell.value;
            case "text":
                return newCell.text;
            case "date":
                return newCell.date;
            case "checkbox":
                return newCell.checked;
            default:
                return "";
        }
    };

    const changeDefaultColumnCell = (column, value, hammerId) => {
        const oldHammers = [...hammers];
        oldHammers[hammersMap[hammerId]][column] = value;
        oldHammers[hammersMap[hammerId]][column] = value;

        if (hammers !== oldHammers) {
            setChanged(true);
        }

        return oldHammers;
    };

    const changeDateColumnCell = (column, value, hammerId) => {
        const oldHammers = [...hammers];
        if (isNaN(value)) {
            if (column in oldHammers[hammersMap[hammerId]]["values"]) {
                setChanged(true);
                delete oldHammers[hammersMap[hammerId]]["values"][column];
            }
        } else {
            setChanged(true);
            oldHammers[hammersMap[hammerId]]["values"][column] = value;
        }

        return oldHammers;
    };

    const changeDimensionColumnCell = (column, value, hammerId) => {
        const oldHammers = [...hammers];
        if (!value) {
            delete oldHammers[hammersMap[hammerId]]["dimensions"][column];
        } else {
            oldHammers[hammersMap[hammerId]]["dimensions"][column] = value.id;
            oldHammers[hammersMap[hammerId]]["metadata"][value.id] = { id: value.id, name: value?.name };
        }
        setChanged(true);

        return oldHammers;
    };

    const onCellsChanged = (changes) => {
        changes.forEach((change) => {
            let newHammers = hammers;

            if (!(change.rowId in hammersMap)) {
                return;
            }

            if (defaultColumns.includes(change.columnId)) {
                newHammers = changeDefaultColumnCell(change.columnId, getCellValue(change), change.rowId);
            } else if (dateColumns.includes(change.columnId)) {
                newHammers = changeDateColumnCell(change.columnId, getCellValue(change), change.rowId);
            } else if (dimensionColumns.includes(change.columnId)) {
                newHammers = changeDimensionColumnCell(change.columnId, getCellValue(change), change.rowId);
            }

            setHammers(newHammers);
        });
    };

    const formatCellsStyle = (row, idx) => {
        return idx % 2 === 0
            ? { ...row, cells: row.cells.map(cell => ({ ...cell, style: { ...cell?.style, background: theme.palette.grey["0"], color: "black" } })) }
            : { ...row, cells: row.cells.map(cell => ({ ...cell, style: { ...cell?.style, background: theme.palette.grey["100"], color: "black" } })) };
    };

    const createHammer = (
        name = "",
        document_type = config.hammer.document_types[0],
        accounting_treatment = config.hammer.accounting_treatments[0],
        measure = HammerMeasure.AMOUNT_KEEP_QUANTITY,
        dimensions = {},
        metadata = {}) => {
        return {
            id: crypto.randomUUID(),
            name: name,
            document_type: document_type,
            accounting_treatment: accounting_treatment,
            measure: measure,
            dimensions: dimensions,
            values: {},
            metadata: metadata,
            enabled: true,
        };
    };

    const addBlankHammer = () => {
        setHammersAndMap([...hammers, createHammer()]);
        setChanged(true);
    };

    const deleteHammer = (id) => {
        const newHammers = [...hammers];
        newHammers.splice(hammersMap[id], 1);
        setHammersAndMap(newHammers);
        setChanged(true);
    };

    if (!rows) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.hammer.loading} />
            </AdvisorContainer>
        );
    }

    return (
        <AdvisorContainer
            title={config.i18n.page.hammer}
            minWidth={0}
            maxWidth="xxl"
            maxHeight="calc(100vh - 70px)" // Ensures the "sticky header"
            actions={(
                <Grid container spacing={2} alignItems="right" justifyContent="right" display="flex">
                    <Grid item size={3.5}>
                        <ClickableDatePicker
                            fullWidth={false}
                            views={["year", "month"]}
                            label={config.i18n.Dimension.DATE_FROM}
                            dataCyProp="startDate_selector"
                            value={startDate}
                            onAccept={date => setStartDate(date.startOf("month").startOf("date"))}
                            minDate={historicalMin}
                            maxDate={endDate}
                        />
                    </Grid>
                    <Grid item size={3.5}>
                        <ClickableDatePicker
                            fullWidth={false}
                            views={["year", "month"]}
                            label={config.i18n.Dimension.DATE_TO}
                            dataCyProp="endDate_selector"
                            value={endDate}
                            onAccept={date => setEndDate(date.endOf("month").endOf("date"))}
                            minDate={startDate}
                            maxDate={historicalMax}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            data-cy="referenceDate_button"
                            variant="contained"
                            size="medium"
                            color="grey"
                            disabled={(startDate.isSame(referenceMin) && endDate.isSame(referenceMax))}
                            onClick={() => {
                                setStartDate(referenceMin);
                                setEndDate(referenceMax);
                            }}
                        >
                            {config.i18n.selector.reference_date_button}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            data-cy="allTimeDate_button"
                            variant="contained"
                            size="medium"
                            color="grey"
                            disabled={(startDate.isSame(historicalMin) && endDate.isSame(historicalMax))}
                            onClick={() => {
                                setStartDate(historicalMin);
                                setEndDate(historicalMax);
                            }}
                        >
                            {config.i18n.selector.all_date_button}
                        </Button>
                    </Grid>
                </Grid>
            )}
        >
            <Box component="div" sx={{ overflowX: "auto", marginTop: 2, marginBottom: 2 }}>
                <ReactGrid
                    customCellTemplates={{
                        mui_dropdown: DropdownCellTemplate,
                        dimension: DimensionCellTemplate,
                        add_button: AddButtonCellTemplate,
                        delete_button: DeleteButtonCellTemplate,
                    }}
                    columns={headerColumns}
                    rows={[
                        getHeaderRow(),
                        ...rows.map((row, idx) => (formatCellsStyle(row, idx))),
                        formatCellsStyle(getBlankRow(addBlankHammer, hammers?.length, headerColumns.length), hammers?.length),
                    ]}
                    onCellsChanged={onCellsChanged}
                    stickyLeftColumns={2}
                    stickyTopRows={1}
                    stickyRightColumns={1}
                    enableRangeSelection
                />
            </Box>
            <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    loading={isSaving}
                    loadingPosition="center"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    title={config.i18n.button.save}
                    style={{ minWidth: 100 }}
                    disabled={!changed || isSaving}
                    onClick={persistHammers}
                >
                    {config.i18n.button.save}
                </Button>

                <Button
                    loading={isCommitting}
                    loadingPosition="center"
                    variant="contained"
                    startIcon={<CheckIcon />}
                    title={config.i18n.button.commit}
                    disabled={changed || isSaving || isCommitting}
                    color="success"
                    style={{ minWidth: 100 }}
                    onClick={commitHammers}
                >
                    {config.i18n.button.commit}
                </Button>
            </div>
        </AdvisorContainer>
    );
};

export default Hammer;
